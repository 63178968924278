import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import TickIcon from '../assets/images/tick.svg';
import HeaderSection from '../containers/HeaderSection';
import FooterSection from '../containers/FooterSection';
import ExpertSection from './ExpertSection';
import QuestionnaireSectionThree from './QuestionnaireSectionThree';
import { Redirect } from 'react-router-dom';
class SurveySectionThree extends Component {
  render() {
    if (this.props.location.state !== undefined) {
      window.sessionStorage.setItem('questionTwoPayload', JSON.stringify(this.props.location.state));
    }
    if (sessionStorage.length === 0) {
      return (
        <Redirect to="/question-one" />
      )
    }
    // if (sessionStorage.getItem('questionOneClickStatus') !== null && sessionStorage.getItem('questionTwoPayload') === null) {
    //   return (
    //     <Redirect to="/question-two" />
    //   )
    // }

    return (
      <Fragment>
        <HeaderSection />
        <section className="survey-section text-center">
          <div className="container">
            <Row>
              <Col>
                <div className="survey-section-inner small">
                  <div className="progress-steps">
                    <ul>
                      <li className="active completed">
                        <div className="circle"><img src={TickIcon} alt="tick" /></div>
                        <h6>STEP 1</h6>
                      </li>
                      <li className="">
                        <div className="circle"><img src={TickIcon} alt="tick" /></div>
                        <h6>STEP 2</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section>
          <div className="container">
            <Row>
              <ExpertSection />
              <QuestionnaireSectionThree />
            </Row>
          </div>
        </section>
        <FooterSection />
      </Fragment>
    )
  }
}

export default SurveySectionThree;