import React, { Component, Fragment } from 'react';
import { Button, Col, Modal, Form } from 'react-bootstrap';
import BottomLogo from '../assets/images/genentech-logo.svg';
class CookieSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCookieSection: true
      // showCookiePopup: false
    };
  }

  // handleShowCookiePopup = () => {
  //   this.setState({
  //     showCookiePopup: true
  //   })
  // }

  // handleCloseCookiePopup = () => {
  //   this.setState({
  //     showCookiePopup: false
  //   })
  // }

  hideCookieSection = () => {
    this.setState({
      showCookieSection: false
    })
    window.sessionStorage.setItem('showCookieSection', false);
  }

  showCookieSectionDiv = () => {
    if (!this.state.showCookieSection) {
      return false;
    } else if (this.state.showCookieSection && window.sessionStorage.getItem('showCookieSection') === 'false') {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <Fragment>
        { this.showCookieSectionDiv() && <section className="cookies-section">
          <div className="container">
            <Col>
                <h6>Our Use of Cookies</h6>
                <p className="small-text mar-b-20">This website may use certain types of cookies and other technologies to personalize content and to show more personalized ads. By clicking “Accept," you understand that you are directing Genentech to disclose data that may be considered personal information to third parties for these purposes. For more details on what information we collect, why we collect it, and to whom we disclose it, please visit our <a href="https://www.gene.com/privacy-policy" className="no-unerline" target="_blank" rel="noreferrer">Privacy Policy.</a></p>
                <Button variant="outline-primary mar-right-25 mar-b-15" onClick={this.hideCookieSection}>Decline</Button>
                <Button variant="primary mar-b-15" onClick={this.hideCookieSection}>Accept</Button>
            </Col>
          </div>
        </section>}

        <div className="treatement-overview-popup">
          <Modal show={this.state.showCookiePopup} onHide={this.handleCloseCookiePopup}>

            <Modal.Body>
            <a href="https://www.gene.com/" target="_blank" rel="noreferrer"><img src={BottomLogo} alt="Genentech-logo" /></a>
            <h4 className="large-heading">Our Use of Cookies</h4>
            <p className="font-14 font-light">This website may use cookies and other technologies to personalize content and advertisements on sites such as Google Ads, Facebook to you on this and other websites and provide social media features on this website, including social media sites such as Facebook, Twitter, LinkedIn. By accepting cookies, you understand that you are directing Genentech to disclose data that may include internet tracking data, cookies and the IP address you used to access this website, which may be considered personal information, to these third parties for the purposes stated above. You can still use this website if you do not accept cookies, but your experience on this and other websites may be different. You can always update these settings in your browser if you change your mind. You also have the ability to customize your cookie preferences below. <a href="https://www.gene.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
            <h4 className="large-heading">Manage Consent Preferences</h4>
            <div className="short-head">
              <h5>Strictly Necessary Cookies</h5>
              <h5 className="text-blue">Always Active</h5>
            </div>
            <p className="font-14 font-light mar-b-15">These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you that amount to a request for services, such as setting your privacy preferences, logging in, or filling in forms. You can set your browser to block or alert you about these cookies, but blocking these cookies will prevent the site from working. These cookies typically do not store any personal data.</p>

            <div className="short-head">
              <h5>Performance Cookies</h5>
              <Form className="cus-cookie-select">
                <Form.Check type="switch" value="performance" id="custom-switch" label=""/>
              </Form>
            </div>
            <p className="font-14 font-light mar-b-15">These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. We may provide these cookies to third party service providers to help us run these analytics. All information these cookies collect is aggregated and do not relate to you personally. If you block or opt out of these cookies, we will not know when you have visited our site, and will not be able to monitor its performance.</p>

            <div className="short-head">
              <h5>Functional Cookies</h5>
              <Form className="cus-cookie-select">
                <Form.Check type="switch" value="functional" id="custom-switch" label=""/>
              </Form>
            </div>
            <p className="font-14 font-light mar-b-15">These cookies enable our websites to provide enhanced functionality and personalization. They may be set by us or by third party service providers whose services we have added to our pages. If you reject these cookies, then some or all of these services may not function properly.</p>

            <div className="short-head">
              <h5>Social Media Cookies</h5>
              <Form className="cus-cookie-select">
                <Form.Check type="switch" value="social" id="custom-switch" label=""/>
              </Form>
            </div>
            <p className="font-14 font-light mar-b-15">These cookies may be set by a range of social media services that we may have added to the site to enable you to share our content with your friends and networks. They are capable of tracking your browser across other sites and building up a profile of your interests. This may impact the content and messages you see on other websites you visit. If you do not allow these cookies, you may not be able to use or see these sharing tools.</p>

            <div className="short-head">
              <h5>Targeting Cookies </h5>
              <Form className="cus-cookie-select">
                <Form.Check type="switch" value="" id="custom-switch" label=""/>
              </Form>
            </div>
            <p className="font-14 font-light mar-b-15">These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant advertisements on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.</p>


            <Button variant="primary" className="confirm-choice-btn">Confirm My Choices</Button>

            </Modal.Body>
          </Modal>
        </div>
      </Fragment>
    )
  }
}

export default CookieSection;