import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import TickIcon from '../assets/images/tick.svg';
import HeaderSection from '../containers/HeaderSection';
import FooterSection from '../containers/FooterSection';
import ExpertSection from '../components/ExpertSection';
import FinalSection from './FinalSection';
import { Redirect } from 'react-router-dom';
class SurveySectionFinal extends Component {
  render() {
    if (this.props.location.state !== undefined) {
      window.sessionStorage.setItem('questionFourPayload', JSON.stringify(this.props.location.state));
    }
    if (sessionStorage.length === 0) {
      return (
        <Redirect to="/question-one" />
      )
    }
    // if (sessionStorage.getItem('questionOneClickStatus') !== null && sessionStorage.getItem('questionTwoPayload') === null) {
    //   return (
    //     <Redirect to="/question-two" />
    //   )
    // }
    // if (sessionStorage.getItem('questionThreeClickStatus') === null) {
    //   return (
    //     <Redirect to="/question-three" />
    //   )
    // }
    // if (sessionStorage.getItem('questionThreeClickStatus') !== null && sessionStorage.getItem('questionFourPayload') === null) {
    //   return (
    //     <Redirect to="/question-four" />
    //   )
    // }
    return (
      <Fragment>
        <HeaderSection />
        <section className="survey-section text-center">
          <div className="container">
            <Row>
              <Col>
                <div className="survey-section-inner small">
                  <div className="progress-steps">
                    <ul className="selection">
                      <li className="active completed">
                        <div className="circle"><img src={TickIcon} alt="tick" /></div>
                        <h6>STEP 1</h6>
                      </li>
                      <li className="active completed">
                        <div className="circle"><img src={TickIcon} alt="tick" /></div>
                        <h6>STEP 2</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section>
          <div className="container">
            <Row>
              <ExpertSection />
              <FinalSection />
            </Row>
          </div>
        </section>
        <section className="reference-section">
          <div className="container">
              <Col>
                <p className="small-text"><b>References: 1.</b> Pertuzumab IV Prescribing Information. Genentech, Inc.; 2020. <b>2.</b> Pertuzumab/trastuzumab/hyaluronidase-zzxf subcutaneous injection Prescribing Information. Genentech, Inc.; 2020. <b>3.</b> Ado-trastuzumab emtansine Prescribing Information. Genentech, Inc.; 2020. <b>4.</b> Trastuzumab IV Prescribing Information. Genentech, Inc.; 2018. <b>5.</b> Trastuzumab and hyaluronidase-oysk subcutaneous injection Prescribing Information. Genentech, Inc.; 2019. <b>6.</b> Trastuzumab-dkst Prescribing Information. Mylan; 2017. <b>7.</b> Trastuzumab-pkrb Prescribing Information. Teva Pharmaceuticals USA, Inc.; 2018. <b>8.</b> Trastuzumab-dttb Prescribing Information. Merck & Co.; 2019. <b>9.</b> Trastuzumab-qyyp Prescribing Information. Pfizer Inc.; 2019. <b>10.</b> Trastuzumab-anns Prescribing Information. Amgen Inc.; 2019. <b>11.</b> Neratinib Prescribing Information. Puma Biotechnology, Inc.; 2020. <b>12.</b> Tucatinib Prescribing Information. Seattle Genetics, Inc.; 2020. <b>13.</b> Fam-trastuzumab deruxtecan-nxki Prescribing Information. Daiichi Sankyo Inc. and AstraZeneca Pharmaceuticals LP; 2019.<div className="cleardiv"></div> <b>14.</b> Lapatinib Prescribing Information. Novartis Pharmaceuticals Corp.; 2018. <b>15.</b> Margetuximab-cmkb Prescribing Information. MacroGenics, Inc.; 2021.</p>
              </Col>
          </div>
        </section>
        <FooterSection />
      </Fragment>
    )
  }
}

export default SurveySectionFinal;