import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
class SurveyStartSection extends Component {
    render() {
        return (
            <section className="survey-section text-center">
                <div className="container">
                    <Row>
                        <Col>
                            <div className="survey-section-inner">
                                <h5>Simply answer a few questions to see FDA-approved treatment options.</h5>
                                <Link to={{
                                    pathname: "/question-one",
                                    state: window.location.pathname
                                }}
                                ><Button variant="outline-primary" >START</Button></Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        )
    }
}

export default SurveyStartSection;