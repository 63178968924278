import React from 'react';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import ArrowImg from '../assets/images/arrow-right.svg';
import { Link } from 'react-router-dom';
class QuestionnaireSectionOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionnaireSectionOneClickStatus: ''
    };
  }

  clickQuestionnaireSectionOne = (questionStatus) => {
    window.sessionStorage.setItem('questionOneClickStatus', questionStatus);
    this.setState({
      questionnaireSectionOneClickStatus: questionStatus
    })
    if (questionStatus === 'No' && window.sessionStorage.getItem('questionTwoPayload') !== null) {
      window.sessionStorage.removeItem('questionTwoPayload');
    }

    if (window.sessionStorage.getItem('questionOneClickStatus') !== null) {
      this.props.updateStepOneStatus(questionStatus);
    }
  }

  isDisabled = () => {
    if (window.sessionStorage.getItem('questionOneClickStatus') !== null) {
      if (window.sessionStorage.getItem('questionOneClickStatus').length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.state.questionnaireSectionOneClickStatus.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  render() {
    return (
      <Col lg="6" md="12" >
        <section className="questionare-section">
          <h3>Treatment history prior to <br></br> HER2+ MBC diagnosis</h3>
          <h6>Has the patient been previously treated for HER2+ early breast cancer (EBC)?</h6>
          <div className="btn-groups">
            <Button variant="outline-primary" className={(window.sessionStorage.getItem('questionOneClickStatus') !== null && window.sessionStorage.getItem('questionOneClickStatus') === 'Yes') ? 'mar-top-18 back-btn-active' : 'mar-top-18'} block onClick={this.clickQuestionnaireSectionOne.bind(this, 'Yes')}>YES</Button>
            <Button variant="outline-primary" className={(window.sessionStorage.getItem('questionOneClickStatus') !== null && window.sessionStorage.getItem('questionOneClickStatus') === 'No') ? 'mar-top-18 back-btn-active' : 'mar-top-18'} block onClick={this.clickQuestionnaireSectionOne.bind(this, 'No')}>NO</Button>
          </div>

          <div className="align-center">
            <Link to={{ pathname: (window.sessionStorage.getItem('questionOneClickStatus') !== null && window.sessionStorage.getItem('questionOneClickStatus') === 'Yes') ? "/question-two" : "/question-three" }} className="color-grey"> <Button variant="outline-secondary" className="mar-top-40 next-btn" disabled={this.isDisabled()}>NEXT <img src={ArrowImg} alt="arrow" /></Button></Link>
          </div>
        </section>
      </Col>
    )
  }
}

export default QuestionnaireSectionOne;