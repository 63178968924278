import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import BottomLogo from '../assets/images/genentech-logo.svg';
class HeaderSection extends Component {
  render() {
    return (
      <header className="header-wrapper text-center">
        <div className="top-header-section">
          <div className="container">
            <div className="top-header-section-inner">
              <p>This website is intended for US healthcare professionals only. This promotional content is brought to you by Genentech.</p>
              <a href="https://www.gene.com/" target="_blank" rel="noreferrer"><img src={BottomLogo} className="top-logo" alt="Logo" /></a>
            </div>

          </div>
        </div>
        <div className="container">
          <Row>
            <Col>

              <div className="title-section">
                <h4>Navigating the treatment landscape for</h4>
                <h1>HER2+ metastatic breast cancer (MBC)</h1>
              </div>
              <div className="title-description">
                <h5 className="mar-b-20">There are more treatment options than ever for your patients with HER2+ MBC.</h5>
                <h6>Every patient is unique. Developing an individualized plan comes with many considerations. This Interactive Tool can help you determine FDA-approved HER2-targeted treatment options for your patient with HER2+ MBC. <b>Get started below!</b></h6>
              </div>
            </Col>
          </Row>
        </div>
      </header>
    )
  }
}

export default HeaderSection;