import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import ClientImg from '../assets/images/Rao_circle.png';
class TestimonialSection extends Component {
    render() {
        return (
            <section className="testimonial-section text-center">
                <div className="container">
                    <Row>
                        <Col>
                            <div className="testimonial-section-inner">
                                <img src={ClientImg} alt="fpo-kol" />
                                <h6>Ruta Divgi Rao, MD</h6>
                                <p className="font-16 mar-b-20">Director, Coleman Foundation Comprehensive Breast Cancer Clinic<br></br>Rush University Medical Center<br></br> Chicago, IL</p>
                                <p className="font-italics">As I work through my treatment recommendation, I consider whether this is recurrent or de novo disease and prior lines of mBC treatment. The interactive tool is helpful as I craft treatment plans because it lays out the current approved HER2+ targeted therapies.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        )
    }
}

export default TestimonialSection;