import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Main from './containers/Main';
import SurveySectionOne from './components/SurveySectionOne';
import SurveySectionTwo from './components/SurveySectionTwo';
import SurveySectionThree from './components/SurveySectionThree';
import SurveySectionFour from './components/SurveySectionFour';
import SurveySectionFinal from './components/SurveySectionFinal';
class App extends Component {
  render() {
    let routes = (
      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/question-one" component={SurveySectionOne} />
        <Route exact path="/question-two" component={SurveySectionTwo} />
        <Route exact path="/question-three" component={SurveySectionThree} />
        <Route exact path="/question-four" component={SurveySectionFour} />
        <Route exact path="/final-survey" component={SurveySectionFinal} />
        {/* <Redirect to="/" /> */}
      </Switch>
    );
    return (
      <div>
        {routes}
      </div>
    );
  }
}

export default withRouter(App);

(function (w) {

  w.URLSearchParams = w.URLSearchParams || function (searchString) {
      var self = this;
      self.searchString = searchString;
      self.get = function (name) {
          var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(self.searchString);
          if (results == null) {
              return null;
          }
          else {
              return decodeURI(results[1]) || 0;
          }
      };
  }

})(window)
