import '../App.css';
import HeaderSection from './HeaderSection';
import FooterSection from './FooterSection';
import SurveyStartSection from '../components/SurveyStartSection';
import TestimonialSection from './TestimonialSection';

function Main() {
  if ('questionOneClickStatus' in sessionStorage) {
    window.sessionStorage.removeItem('questionOneClickStatus');
  }
  if ('questionThreeClickStatus' in sessionStorage) {
    window.sessionStorage.removeItem('questionThreeClickStatus');
  }
  if ('questionTwoPayload' in sessionStorage) {
    window.sessionStorage.removeItem('questionTwoPayload');
  }
  if ('questionFourPayload' in sessionStorage) {
    window.sessionStorage.removeItem('questionFourPayload');
  }

  return (
    <div className="App">
      <HeaderSection />
      <SurveyStartSection />
      <TestimonialSection />
      <FooterSection />
    </div>
  );
}

export default Main;