import React, { Component } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { Form, Button } from 'react-bootstrap';
import ArrowImg from '../assets/images/arrow-right.svg';
import DocDwnImg from '../assets/images/doc-download.svg';
import CheckImg from '../assets/images/check.svg';
import { Link } from 'react-router-dom';
class QuestionnaireSectionTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPertuzumab: false,
      showAdo: false,
      showTrastuzumab: false,
      showNeratinib: false,
      showLepatinib: false,
      showTucatinibAndTras: false,
      showFamTrastuzumab: false,
      questionTwoPayload: []
    };
  }

  componentDidMount() {
    if (window.sessionStorage.getItem('questionTwoPayload') !== null) {
      this.setState({
        questionTwoPayload: JSON.parse(window.sessionStorage.getItem('questionTwoPayload'))
      })
    }
  }

  handleShowPertuzumab = () => {
    this.setState({ showPertuzumab: true });
  }

  handleClosePertuzumab = () => {
    this.setState({ showPertuzumab: false });
  }

  handleShowAdo = () => {
    this.setState({ showAdo: true });
  }

  handleCloseAdo = () => {
    this.setState({ showAdo: false });
  }

  handleShowTrastuzumab = () => {
    this.setState({ showTrastuzumab: true });
  }

  handleCloseTrastuzumab = () => {
    this.setState({ showTrastuzumab: false });
  }

  handleShowNeratinib = () => {
    this.setState({ showNeratinib: true });
  }

  handleCloseNeratinib = () => {
    this.setState({ showNeratinib: false });
  }

  handleShowLapatinib = () => {
    this.setState({ showLepatinib: true })
  }

  handleCloseLapatinib = () => {
    this.setState({ showLepatinib: false })
  }

  handleShowTucatinibAndTras = () => {
    this.setState({ showTucatinibAndTras: true })
  }

  handleCloseTucatinibAndTras = () => {
    this.setState({ showTucatinibAndTras: false })
  }

  handleShowFamTrastuzumab = () => {
    this.setState({ showFamTrastuzumab: true });
  }

  handleCloseFamTrastuzumab = () => {
    this.setState({ showFamTrastuzumab: false });
  }

  createQuestionTwoPayload = (e) => {
    let key;
    let orderKey;
    let linkText;
    let answerTitle;
    let text;

    if (e.target.value === 'PERTUZUMAB-BASED THERAPY') {
      key = 'first-section';
      orderKey = 'a';
      linkText = 'View Indications & Safety, incl. Boxed Warnings';
      answerTitle = 'Pertuzumab-based therapy';
      text = '1,2'
    } else if (e.target.value === 'ADO-TRASTUZUMAB EMTANSINE (T-DM1)') {
      key = 'adotrastuzumab';
      orderKey = 'b';
      linkText = 'View Indications & Safety, incl. Boxed Warnings';
      answerTitle = 'Ado-trastuzumab emtansine (T-DM1)';
      text = '3'
    } else if (e.target.value === 'TRASTUZUMAB PRODUCTS-BASED THERAPY') {
      key = 'trastuzumab';
      orderKey = 'c';
      linkText = 'View Indications & Safety, incl. Boxed Warnings';
      answerTitle = 'Trastuzumab products-based therapy';
      text = '4-10';
    } else if (e.target.value === 'NERATINIB‐BASED THERAPY') {
      key = 'neratinib';
      orderKey = 'd';
      linkText = 'View Indications';
      answerTitle = 'Neratinib-based therapy';
      text = '11';
    } else if (e.target.value === 'OTHER') {
      key = '';
      orderKey = 'e';
      linkText = null;
      answerTitle = 'Other';
      text = '';
    }

    const position = this.state.questionTwoPayload.findIndex(answer => (answer.answerName === e.target.value));
    let payload = this.state.questionTwoPayload;
    if (e.target.checked) {
      payload.push({ answerName: e.target.value, answerLink: key, orderKey: orderKey, linkText: linkText, answerTitle: answerTitle, text: text });
    } else {
      payload.splice(position, 1);
    }

    this.setState({
      questionTwoPayload: payload.sort((a, b) => a.orderKey.localeCompare(b.orderKey))
    })
  }

  isDisabled = () => {
    if (window.sessionStorage.getItem('questionTwoPayload') !== null) {
      if (JSON.parse(window.sessionStorage.getItem('questionTwoPayload')).length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.state.questionTwoPayload.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  render() {
    return (
      <Col lg="6" md="12" >
        <section className="questionare-section">
          <h3>Treatment history prior to <br></br> HER2+ MBC diagnosis</h3>
          <h6>Has the patient been previously treated for HER2+ early breast cancer (EBC)?</h6>
          <div className="show-result-wrap">
            <div className="show-result">
              <img src={CheckImg} alt="check" />
              <p>{window.sessionStorage.getItem('questionOneClickStatus')}</p>
            </div>
          </div>

          <div className="question-row">
          { window.sessionStorage.getItem('questionOneClickStatus') !== null && window.sessionStorage.getItem('questionOneClickStatus') === 'Yes' && <h6 className="mar-b-15">Select any prior treatments for HER2+ EBC.</h6> }
            <Form className="ans-choice">
              <p>Select all that apply.</p>
              <div className="ans-options-wrap">
                <div className={(this.state.questionTwoPayload.filter(question => question.answerName === 'PERTUZUMAB-BASED THERAPY').length > 0) ? "ans-options ans-options-active" : 'ans-options'}>
                  <input type="checkbox" id="one" name="one" value="PERTUZUMAB-BASED THERAPY" onChange={(e) => this.createQuestionTwoPayload(e)} checked={(this.state.questionTwoPayload.filter(question => question.answerName === 'PERTUZUMAB-BASED THERAPY').length > 0)} />
                  <label htmlFor="one">Pertuzumab-based therapy<sup>1,2</sup></label>
                </div>
                <p className="link-anchor" onClick={this.handleShowPertuzumab}>View Indications & Safety, incl. Boxed Warnings</p>
              </div>
              <div className="ans-options-wrap">
                <div className={(this.state.questionTwoPayload.filter(question => question.answerName === 'ADO-TRASTUZUMAB EMTANSINE (T-DM1)').length > 0) ? "ans-options ans-options-active" : 'ans-options'}>
                  <input type="checkbox" id="two" name="two" value="ADO-TRASTUZUMAB EMTANSINE (T-DM1)"
                    onChange={(e) => this.createQuestionTwoPayload(e)} checked={(this.state.questionTwoPayload.filter(question => question.answerName === 'ADO-TRASTUZUMAB EMTANSINE (T-DM1)').length > 0)} />
                  <label htmlFor="two">Ado‐trastuzumab emtansine (T-DM1)<sup>3</sup></label>
                </div>
                <p className="link-anchor" onClick={this.handleShowAdo}>View Indications & Safety, incl. Boxed Warnings</p>
              </div>
              <div className="ans-options-wrap">
                <div className={(this.state.questionTwoPayload.filter(question => question.answerName === 'TRASTUZUMAB PRODUCTS-BASED THERAPY').length > 0) ? "ans-options ans-options-active" : 'ans-options'}>
                  <input type="checkbox" id="three" name="three" value="TRASTUZUMAB PRODUCTS-BASED THERAPY"
                    onChange={(e) => this.createQuestionTwoPayload(e)} checked={(this.state.questionTwoPayload.filter(question => question.answerName === 'TRASTUZUMAB PRODUCTS-BASED THERAPY').length > 0)} />
                  <label htmlFor="three">Trastuzumab products-based therapy<sup>4-10</sup></label>
                </div>
                <p className="link-anchor" onClick={this.handleShowTrastuzumab}>View Indications & Safety, incl. Boxed Warnings</p>
              </div>
              <div className="ans-options-wrap">
                <div className={(this.state.questionTwoPayload.filter(question => question.answerName === 'NERATINIB‐BASED THERAPY').length > 0) ? "ans-options ans-options-active" : 'ans-options'}>
                  <input type="checkbox" id="four" name="four" value="NERATINIB‐BASED THERAPY"
                    onChange={(e) => this.createQuestionTwoPayload(e)} checked={(this.state.questionTwoPayload.filter(question => question.answerName === 'NERATINIB‐BASED THERAPY').length > 0)} />
                  <label htmlFor="four">NERATINIB‐BASED THERAPY<sup>11</sup></label>
                </div>
                <p className="link-anchor" onClick={this.handleShowNeratinib}>View Indications</p>
              </div>
              <div className="ans-options-wrap">
                <div className={(this.state.questionTwoPayload.filter(question => question.answerName === 'OTHER').length > 0) ? "ans-options ans-options-active" : 'ans-options'}>
                  <input type="checkbox" id="five" name="five" value="OTHER"
                    onChange={(e) => this.createQuestionTwoPayload(e)} checked={(this.state.questionTwoPayload.filter(question => question.answerName === 'OTHER').length > 0)} />
                  <label htmlFor="five">Other</label>
                </div>
              </div>
            </Form>
          </div>

          <div className="align-center">
            <Link to="/question-one" className="color-grey"><Button variant="link" className="mar-top-40 back-btn back-btn-align"><img src={ArrowImg} alt="arrow" />BACK</Button></Link>
            <Link to={{ pathname: "/question-three", state: this.state.questionTwoPayload }} className="color-grey"><Button variant="outline-secondary" className="mar-top-40 next-btn" disabled={this.isDisabled()}>NEXT <img src={ArrowImg} alt="arrow" /></Button></Link>
          </div>


          {/* popup Content  */}

          
          <div className="treatement-overview-popup">
            <Modal show={this.state.showPertuzumab} onHide={this.handleClosePertuzumab}>
              <Modal.Header closeButton>
                  Pertuzumab-based therapy Indications & Safety, incl. BOXED WARNINGS
                </Modal.Header>
              <Modal.Body>
                <div className="mar-b-15"><a href="#PertuzumabIV" className="flex-baseline"><img src={DocDwnImg} alt="doc" width="8" height="12" /> <p>Pertuzumab IV</p></a></div>
                <div className="mar-b-15"><a href="#PertuzumabTrastuzumab" className="flex-baseline"><img src={DocDwnImg} alt="doc" width="8" height="12" /> <p>Pertuzumab/trastuzumab/hyaluronidase‐zzxf subcutaneous injection</p></a></div>
                <p className="small-heading mar-b-15" id="PertuzumabIV">Pertuzumab IV </p>
                <p className="font-14 font-bold mar-b-10 "><b>Indications</b></p>
                <p className="font-14 font-bold mar-b-10 ">Early Breast Cancer</p>
                <p className=" font-14 font-light">Pertuzumab is indicated for use in combination with trastuzumab and chemotherapy for</p>
                <ul className="font-14 font-light popup-list">
                  <li>the neoadjuvant treatment of patients with HER2-positive, locally advanced, inflammatory, or early stage breast cancer (either greater than 2 cm in diameter or node-positive) as part of a complete treatment regimen for early breast cancer (EBC)</li>
                  <li>the adjuvant treatment of patients with HER2-positive early breast cancer (EBC) at high risk of recurrence</li>
                </ul>
                <p className="font-14 font-bold mar-b-10">Metastatic Breast Cancer</p>
                <p className="font-14 font-light mar-b-10">Pertuzumab is indicated for use in combination with trastuzumab and docetaxel for the treatment of patients with HER2-positive metastatic breast cancer (MBC) who have not received prior anti-HER2 therapy or chemotherapy for metastatic disease</p>
                <p className="font-14 font-bold mar-b-10">Important Safety Information</p>
                <p className="font-14 font-bold mar-b-10">BOXED WARNINGS: Left Ventricular Dysfunction and Embryo‐Fetal Toxicity</p>
                <ul className="font-14 font-bold popup-list">
                  <li>Pertuzumab can result in subclinical and clinical cardiac failure manifesting as decreased left ventricular ejection fraction (LVEF) and congestive heart failure (CHF). Evaluate cardiac function prior to and during treatment. Discontinue pertuzumab treatment for a confirmed clinically significant decrease in left ventricular function</li>
                  <li>Exposure to pertuzumab can result in embryo‐fetal death and birth defects. Advise patients of these risks and the need for effective contraception</li>
                </ul>
                <p className="font-14 font-bold mar-b-10">Additional Important Safety Information</p>
                <ul className="font-14 font-light popup-list">
                  <li>Pertuzumab is contraindicated in patients with known hypersensitivity to pertuzumab or to any of its excipients</li>
                  <li>Pertuzumab has been associated with severe and sometimes fatal infusion and hypersensitivity reactions/anaphylaxis</li>
                </ul>
                <p className="font-14 font-bold mar-b-10">Most Common Adverse Reactions <br></br>In the neoadjuvant treatment of breast cancer</p>
                <ul className="font-14 font-light popup-list">
                  <li>The most common adverse reactions (&gt;30%) with pertuzumab in combination with trastuzumab and docetaxel administered for 4 cycles were alopecia, neutropenia, diarrhea, and nausea</li>
                  <li>The most common adverse reactions (&gt;30%) with pertuzumab in combination with trastuzumab and docetaxel administered for 3 cycles following 3 cycles of 5‐fluorouracil, epirubicin, and cyclophosphamide (FEC) were diarrhea, nausea, alopecia, neutropenia, vomiting, and fatigue</li>
                  <li>The most common adverse reactions (&gt;30%) with pertuzumab in combination with docetaxel, carboplatin, and trastuzumab (TCH) administered for 6 cycles were diarrhea, alopecia, neutropenia, nausea, fatigue, vomiting, anemia, and thrombocytopenia</li>
                  <li>The most common adverse reactions (&gt;30%) with pertuzumab in combination with trastuzumab and paclitaxel administered for 4 cycles following 4 cycles of dose‐dense doxorubicin and cyclophosphamide (ddAC) were nausea, diarrhea, alopecia, fatigue, constipation, peripheral neuropathy, and headache</li>
                  <li>The most common adverse reactions (&gt;30%) with pertuzumab in combination with trastuzumab and docetaxel for 4 cycles following 4 cycles of FEC were diarrhea, nausea, alopecia, asthenia, constipation, fatigue, mucosal inflammation, vomiting, myalgia, and anemia</li>
                </ul>
                <p className="font-14 font-light mar-b-10"><b className="font-bold">In the adjuvant treatment of breast cancer,</b> the most common adverse reactions (&gt;30%) with pertuzumab in combination with trastuzumab and chemotherapy were diarrhea, nausea, alopecia, fatigue, peripheral neuropathy, and vomiting.</p>
                <p className="font-14 font-light mar-b-10"><b className="font-bold">In the treatment of metastatic breast cancer,</b> the most common adverse reactions (&gt;30%) seen with pertuzumab in combination with trastuzumab and docetaxel were diarrhea, alopecia, neutropenia, nausea, fatigue, rash, and peripheral neuropathy.</p>
                <div className="font-14 font-light mar-b-10">You may report side effects to the FDA at 1‐800‐FDA‐1088 or <a href="https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program" target="_blank" rel="noopener noreferrer">www.fda.gov/medwatch</a>. You may also report side effects to Genentech at <span className="blue-color text-underline">1‐888‐835‐2555.</span></div>
                <p className="font-14 font-bold mar-b-10"><a href="https://www.gene.com/download/pdf/perjeta_prescribing.pdf" target="_blank" rel="noopener noreferrer">Click here</a> for full Prescribing Information for additional Important Safety Information, including BOXED WARNINGS.</p>
                <p className="small-heading mar-b-10" id="PertuzumabTrastuzumab">Pertuzumab/trastuzumab/hyaluronidase‐zzxf subcutaneous injection</p>
                <p className="font-14 font-bold mar-b-10 "><b>Indications</b></p>
                <p className="font-14 font-bold mar-b-10 "><b>Early Breast Cancer</b></p>
                <p className=" font-14 font-light">Pertuzumab, trastuzumab, and hyaluronidase‐zzxf is indicated for use in combination with chemotherapy for</p>
                <ul className="font-14 font-light popup-list">
                  <li>the neoadjuvant treatment of adult patients with HER2‐positive, locally advanced, inflammatory, or early stage breast cancer (either greater than 2 cm in diameter or node‐positive) as part of a complete treatment regimen for early breast cancer (EBC)</li>
                  <li>the adjuvant treatment of adult patients with HER2‐positive EBC at high risk of recurrence</li>
                </ul>
                <p className=" font-14 font-light mar-b-15">Select patients for therapy based on an FDA‐approved companion diagnostic test.</p>
                <p className="font-14 font-bold mar-b-10 "><b>Metastatic Breast Cancer</b></p>
                <p className=" font-14 font-light mar-b-15">Pertuzumab, trastuzumab, and hyaluronidase‐zzxf is indicated for use in combination with docetaxel for the treatment of adult patients with HER2‐positive metastatic breast cancer (MBC) who have not received prior anti‐HER2 therapy or chemotherapy for metastatic disease.</p>
                <p className=" font-14 font-light mar-b-15">Select patients for therapy based on an FDA‐approved companion diagnostic test.</p>
                <p className="font-14 font-bold mar-b-10">Important Safety Information</p>
                <p className="font-14 font-bold mar-b-10">BOXED WARNINGS: Cardiomyopathy, Embryo‐Fetal Toxicity, and Pulmonary Toxicity</p>
                <ul className="font-14 font-bold popup-list">
                  <li>Pertuzumab, trastuzumab, and hyaluronidase‐zzxf administration can result in subclinical and clinical cardiac failure. The incidence and severity was highest in patients receiving pertuzumab, trastuzumab, and hyaluronidase‐zzxf with anthracycline‐containing chemotherapy regimens. Evaluate cardiac function prior to and during treatment with pertuzumab, trastuzumab, and hyaluronidase‐zzxf. Discontinue pertuzumab, trastuzumab, and hyaluronidase‐zzxf treatment in patients receiving adjuvant therapy and withhold pertuzumab, trastuzumab, and hyaluronidase‐zzxf in patients with metastatic disease for clinically significant decrease in left ventricular function</li>
                  <li>Exposure to pertuzumab, trastuzumab, and hyaluronidase‐zzxf can result in embryo‐fetal death and birth defects, including oligohydramnios and oligohydramnios sequence manifesting as pulmonary hypoplasia, skeletal abnormalities, and neonatal death. Advise patients of these risks and the need for effective contraception</li>
                  <li>Pertuzumab, trastuzumab, and hyaluronidase‐zzxf administration can result in serious and fatal pulmonary toxicity. Discontinue pertuzumab, trastuzumab, and hyaluronidase‐zzxf for anaphylaxis, angioedema, interstitial pneumonitis, or acute respiratory distress syndrome. Monitor patients until symptoms completely resolve</li>
                </ul>
                <p className="font-14 font-bold mar-b-10">Contraindications</p>
                <p className=" font-14 font-light mar-b-15">Pertuzumab, trastuzumab, and hyaluronidase‐zzxf is contraindicated in patients with known hypersensitivity to pertuzumab, or trastuzumab, or hyaluronidase, or to any of its excipients.</p>
                <p className="font-14 font-bold mar-b-10">Additional Important Safety Information</p>
                <ul className="font-14 font-light popup-list">
                  <li>Exacerbation of chemotherapy‐induced neutropenia</li>
                  <li>Hypersensitivity and administration‐related reactions (ARRs): Monitor patients for systemic hypersensitivity reactions. Permanently discontinue pertuzumab, trastuzumab, and hyaluronidase‐zzxf in patients who experience anaphylaxis or severe hypersensitivity reactions</li>
                </ul>
                <p className="font-14 font-bold mar-b-10">Most Common Adverse Reactions</p>
                <p className="font-14 font-bold mar-b-10">Early Breast Cancer</p>
                <p className=" font-14 font-light mar-b-15">The most common adverse reactions (&gt;30%) with pertuzumab, trastuzumab, and hyaluronidase‐zzxf were alopecia, nausea, diarrhea, anemia, and asthenia.</p>
                <p className="font-14 font-bold mar-b-10">Metastatic Breast Cancer (based on IV pertuzumab)</p>
                <p className=" font-14 font-light mar-b-15">The most common adverse reactions (&gt;30%) with pertuzumab in combination with trastuzumab and docetaxel were diarrhea, alopecia, neutropenia, nausea, fatigue, rash, and peripheral neuropathy.</p>
                <div className=" font-14 font-light mar-b-15">You are encouraged to report side effects to Genentech and the FDA. You may report side effects to the FDA at 1‐800‐FDA‐1088 or <a href="https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program" target="_blank" rel="noopener noreferrer">www.fda.gov/medwatch</a>. You may also report side effects to Genentech at <span className="blue-color text-underline">1‐888‐835‐2555.</span></div>
                <p className="font-14 font-bold mar-b-10"><a href="https://www.gene.com/download/pdf/phesgo_prescribing.pdf" target="_blank" rel="noopener noreferrer">Click here</a> for full Prescribing Information for additional Important Safety Information, including BOXED WARNINGS.</p>
              </Modal.Body>
            </Modal>
          </div>



          <div className="treatement-overview-popup">
            <Modal show={this.state.showAdo} onHide={this.handleCloseAdo}>
              <Modal.Header closeButton>
                  Ado-trastuzumab emtansine (T-DM1) Indications & Safety, incl. BOXED WARNINGS
                  </Modal.Header>
              <Modal.Body>
                <p className="font-14 font-bold mar-b-10 "><b>Indications</b></p>
                <p className="font-14 font-bold mar-b-10 ">Early Breast Cancer (EBC)</p>
                <p className=" font-14 font-light mar-b-10">Ado‐trastuzumab emtansine, as a single agent, is indicated for the adjuvant treatment of patients with HER2‐positive early breast cancer who have residual invasive disease after neoadjuvant taxane and trastuzumab‐based treatment.</p>
                <p className=" font-14 font-light mar-b-10">Select patients for therapy based on an FDA‐approved companion diagnostic for ado‐ trastuzumab emtansine. </p>
                <p className="font-14 font-bold mar-b-10">Metastatic Breast Cancer (MBC)</p>
                <p className="font-14 font-light mar-b-10">Ado‐trastuzumab emtansine, as a single agent, is indicated for the treatment of patients with HER2‐positive, metastatic breast cancer who previously received trastuzumab and a taxane, separately or in combination. Patients should have either:</p>
                <ul className="font-14 font-light popup-list">
                  <li>Received prior therapy for metastatic disease, or</li>
                  <li>Developed disease recurrence during or within six months of completing adjuvant therapy.</li>
                </ul>
                <p className="font-14 font-light mar-b-10">Select patients for therapy based on an FDA‐approved companion diagnostic for ado‐ trastuzumab emtansine.</p>
                <p className="font-14 font-bold mar-b-10">Important Safety Information</p>
                <p className="font-14 font-bold mar-b-10">BOXED WARNINGS: HEPATOTOXICITY, CARDIAC TOXICITY, EMBRYO‐FETAL TOXICITY</p>
                <ul className="font-14 font-bold popup-list">
                  <li>Hepatotoxicity: Serious hepatotoxicity has been reported, including liver failure and death in patients treated with ado‐trastuzumab emtansine. Monitor serum transaminases and bilirubin prior to initiation of ado‐trastuzumab emtansine treatment and prior to each ado‐trastuzumab emtansine dose. Reduce dose or discontinue ado‐trastuzumab emtansine as appropriate in cases of increased serum transaminases or total bilirubin</li>
                  <li>Cardiac Toxicity: Ado‐trastuzumab emtansine administration may lead to reductions in left ventricular ejection fraction (LVEF). Evaluate left ventricular function in all patients prior to and during treatment with ado‐trastuzumab emtansine. Withhold treatment for clinically significant decrease in left ventricular function</li>
                  <li>Embryo‐Fetal Toxicity: Exposure to ado‐trastuzumab emtansine can result in embryo‐fetal death or birth defects. Advise patients of these risks and the need for effective contraception</li>
                </ul>

                <p className="font-14 font-bold mar-b-10">Additional Important Safety Information</p>
                <ul className="font-14 font-light popup-list">
                  <li>Interstitial lung disease (ILD), including pneumonitis, some leading to acute respiratory distress syndrome or fatality: Permanently discontinue ado‐trastuzumab emtansine in patients diagnosed with ILD or pneumonitis</li>
                  <li>Infusion‐related reactions (IRR), hypersensitivity: Ado‐trastuzumab emtansine treatment should be interrupted in patients with severe IRR and permanently discontinued in the event of a life‐threatening IRR</li>
                  <li>Hemorrhage: Fatal cases of hemorrhage occurred in clinical trials among patients with no known identified risk factors, as well as among patients with thrombocytopenia and those receiving anticoagulation and antiplatelet therapy. Use caution with these agents and consider additional monitoring when concomitant use is medically necessary</li>
                  <li>Thrombocytopenia: Monitor platelet counts prior to initiation of ado‐trastuzumab emtansine and prior to each dose. Institute dose modifications as appropriate </li>
                  <li>Peripheral neuropathy: Temporarily discontinue ado‐trastuzumab emtansine in patients experiencing Grade 3 or 4 peripheral neuropathy until resolution to ≤ Grade 2</li>
                  <li>Reactions secondary to extravasation: Closely monitor the infusion site for possible subcutaneous infiltration during drug administration</li>
                  <li>In early breast cancer, the most common adverse reactions seen with ado‐trastuzumab emtansine in the KATHERINE trial (frequency &gt;25%) were fatigue, nausea, increased transaminases, musculoskeletal pain, hemorrhage, thrombocytopenia, headache, peripheral neuropathy, and arthralgia</li>
                  <li>In metastatic breast cancer, the most common adverse reactions (≥25%) with ado‐ trastuzumab emtansine were fatigue, nausea, musculoskeletal pain, hemorrhage, thrombocytopenia, headache, increased transaminases, constipation and epistaxis</li>
                  <li>Advise women not to breastfeed during treatment and for 7 months following the last dose of ado‐trastuzumab emtansine</li>
                </ul>
                <p className="font-14 font-light mar-b-10">You are encouraged to report side effects to Genentech and the FDA. You may contact Genentech by calling 1‐888‐835‐2555. You may contact the FDA by visiting <a href="https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program" target="_blank" rel="noopener noreferrer">www.fda.gov/medwatch</a> or calling 1‐800‐FDA‐1088.</p>
                <p className="font-14 font-bold mar-b-10"><a href="https://www.gene.com/download/pdf/kadcyla_prescribing.pdf" target="_blank" rel="noopener noreferrer">Click here</a> for full Prescribing Information for additional Important Safety Information, including BOXED WARNINGS.</p>
              </Modal.Body>
            </Modal>
          </div>

                
          <div className="treatement-overview-popup">
            <Modal show={this.state.showTrastuzumab} onHide={this.handleCloseTrastuzumab}>
              <Modal.Header closeButton>
                  Trastuzumab products-based therapy INDICATIONS & SAFETY, INCL. BOXED WARNINGS
                </Modal.Header>
              <Modal.Body>
                <p className=" font-14 font-bold mar-b-10">Please see full Prescribing Information for trastuzumab‐dkst, ‐pkrb, ‐dttb, ‐qyyp, ‐anns for Indications and Important Safety Information, including BOXED WARNINGS.</p>
                <p className="mar-b-15"><a href="#trastuzumab" className="flex-baseline"><img src={DocDwnImg} alt="doc" width="8" height="12" /> <p>Trastuzumab IV</p></a></p>
                <p className="mar-b-15"><a href="#trastuzumabHya" className="flex-baseline"><img src={DocDwnImg} alt="doc" width="8" height="12" /> <p>Trastuzumab and hyaluronidase‐oysk subcutaneous injection</p></a></p>
                <p className="small-heading mar-b-15" id="trastuzumab">Trastuzumab IV</p>
                <p className="font-14 font-bold mar-b-10 "><b>Indications</b></p>
                <p className="font-14 font-bold mar-b-10 ">Adjuvant Breast Cancer</p>

                <p className=" font-14 font-light mar-b-10">Trastuzumab is indicated for adjuvant treatment of HER2‐overexpressing node‐positive or node‐negative (ER/PR‐negative or with one high‐risk feature*) breast cancer:</p>
                <ul className="font-14 font-light popup-list">
                  <li>As part of a treatment regimen containing doxorubicin, cyclophosphamide and either paclitaxel or docetaxel</li>
                  <li>With docetaxel and carboplatin</li>
                  <li>As a single agent following multi‐modality anthracycline‐based therapy</li>
                </ul>

                <p className="font-14 font-light mar-b-10">Select patients for therapy based on an FDA‐approved companion diagnostic for trastuzumab.</p>
                <p className="font-10 font-light mar-b-10">*High‐risk is defined as ER/PR‐positive with one of the following features: tumor size &gt;2 cm, age &#60;35 years, or tumor grade 2 or 3.</p>
                <p className="font-14 font-bold mar-b-10 "><b>Metastatic Breast Cancer</b></p>
                <p className="font-14 font-light mar-b-10"> Trastuzumab is indicated:</p>
                <ul className="font-14 font-light popup-list">
                  <li>In combination with paclitaxel for the first line treatment of HER2‐overexpressing metastatic breast cancer</li>
                  <li>As a single agent for treatment of HER2‐overexpressing breast cancer in patients who have received one or more chemotherapy regimens for metastatic disease</li>
                </ul>

                <p className="font-14 font-light mar-b-10">Select patients for therapy based on an FDA‐approved companion diagnostic for trastuzumab.</p>


                <p className="font-14 font-bold mar-b-10">BOXED WARNINGS and Additional Important Safety Information</p>
                <p className="font-14 font-bold mar-b-10">Cardiomyopathy</p>
                <ul className="font-14 font-bold popup-list">
                  <li>Trastuzumab administration can result in sub‐clinical and clinical cardiac failure. The incidence and severity was highest in patients receiving trastuzumab with anthracycline‐containing chemotherapy regimens.</li>
                  <li>Evaluate left ventricular function in all patients prior to and during treatment with trastuzumab. Discontinue trastuzumab treatment in patients receiving adjuvant therapy and withhold trastuzumab in patients with metastatic disease for clinically significant decrease in left ventricular function </li>
                </ul>
                <p className="font-14 font-bold mar-b-10">Infusion Reactions; Pulmonary Toxicity</p>
                <ul className="font-14 font-bold popup-list">
                  <li>Trastuzumab administration can result in serious and fatal infusion reactions and pulmonary toxicity. Symptoms usually occur during or within 24 hours of trastuzumab administration. Interrupt trastuzumab infusion for dyspnea or clinically significant hypotension. Monitor patients until symptoms completely resolve. Discontinue trastuzumab for anaphylaxis, angioedema, interstitial pneumonitis, or acute respiratory distress syndrome </li>
                </ul>
                <p className="font-14 font-bold mar-b-10">Embryo‐Fetal Toxicity</p>
                <ul className="font-14 font-bold popup-list">
                  <li>Exposure to trastuzumab during pregnancy can result in oligohydramnios and oligohydramnios sequence manifesting as pulmonary hypoplasia, skeletal abnormalities, and neonatal death. Advise patients of these risks and the need for effective contraception</li>
                </ul>
                <ul className="font-14 font-light popup-list">
                  <li>Exacerbation of chemotherapy‐induced neutropenia has also occurred</li>
                  <li>The most common adverse reactions associated with trastuzumab use were fever, nausea, vomiting, infusion reactions, diarrhea, infections, increased cough, headache, fatigue, dyspnea, rash, neutropenia, anemia, and myalgia</li>
                </ul>

                <p className="font-14 font-light mar-b-10">You may report side effects to the FDA at (800) FDA‐1088 or <a href="https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program" target="_blank" rel="noopener noreferrer">www.fda.gov/medwatch</a>. You may also report side effects to Genentech at (888) 835‐2555.</p>

                <p className="font-14 font-bold mar-b-15"><a href="https://www.gene.com/download/pdf/herceptin_prescribing.pdf" target="_blank" rel="noopener noreferrer">Click here</a> for full Prescribing Information for additional Important Safety Information, including BOXED WARNINGS.</p>
                <p className="small-heading font-bold mar-b-10" id="trastuzumabHya">Trastuzumab and hyaluronidase‐oysk subcutaneous injection</p>
                <p className="font-14 font-bold mar-b-10">Indications</p>
                <p className="font-14 font-bold mar-b-10">Adjuvant Breast Cancer</p>
                <p className="font-14 font-light mar-b-10">Trastuzumab and hyaluronidase‐oysk is indicated for adjuvant treatment of adults with HER2‐overexpressing node‐positive or node‐negative (ER/PR‐negative or with one high‐risk feature*) breast cancer:</p>
                <ul className="font-14 font-light popup-list">
                  <li>As part of a treatment regimen containing doxorubicin, cyclophosphamide and either paclitaxel or docetaxel</li>
                  <li>With docetaxel and carboplatin</li>
                  <li>As a single agent following multi‐modality anthracycline‐based therapy</li>
                </ul>
                <p className="font-14 font-light mar-b-10">Select patients for therapy based on an FDA‐approved companion diagnostic for trastuzumab.</p>
                <p className="font-10 font-light mar-b-10">*High‐risk is defined as ER/PR‐positive with one of the following features: tumor size &gt;2 cm, age &#60;35 years, or tumor grade 2 or 3.</p>
                <p className="font-14 font-bold mar-b-10">Metastatic Breast Cancer</p>
                <p className="font-14 font-light mar-b-10">Trastuzumab and hyaluronidase‐oysk is indicated in adults:</p>
                <ul className="font-14 font-light popup-list">
                  <li>In combination with paclitaxel for the first line treatment of HER2‐overexpressing metastatic breast cancer</li>
                  <li>As a single agent for treatment of HER2‐overexpressing breast cancer in patients who have received one or more chemotherapy regimens for metastatic disease</li>
                </ul>
                <p className="font-14 font-light mar-b-15">Select patients for therapy based on an FDA‐approved companion diagnostic for trastuzumab.</p>
                <p className="font-14 font-bold mar-b-10">BOXED WARNINGS and Additional Important Safety Information</p>
                <p className="font-14 font-bold mar-b-10">Cardiomyopathy</p>
                <ul className="font-14 font-bold popup-list">
                  <li>Trastuzumab and hyaluronidase‐oysk administration can result in sub‐clinical and clinical cardiac failure. The incidence and severity was highest in patients receiving trastuzumab and hyaluronidase‐oysk with anthracycline‐containing chemotherapy regimens.</li>
                  <li>Evaluate left ventricular function in all patients prior to and during treatment with trastuzumab and hyaluronidase‐oysk. Discontinue trastuzumab and hyaluronidase‐oysk treatment in patients receiving adjuvant therapy and withhold trastuzumab and hyaluronidase‐oysk in patients with metastatic disease for clinically significant decrease in left ventricular function</li>
                </ul>
                <p className="font-14 font-bold mar-b-10">Pulmonary Toxicity</p>
                <ul className="font-14 font-bold popup-list">
                  <li>Trastuzumab and hyaluronidase‐oysk administration can result in serious and fatal pulmonary toxicity. Symptoms usually occur during or within 24 hours of trastuzumab and hyaluronidase‐oysk administration. Discontinue trastuzumab and hyaluronidase‐oysk for anaphylaxis, angioedema, interstitial pneumonitis, or acute respiratory distress syndrome. Monitor patients until symptoms completely resolve</li>
                </ul>
                <p className="font-14 font-bold mar-b-10">Embryo‐Fetal Toxicity</p>
                <ul className="font-14 font-bold popup-list">
                  <li>Exposure to trastuzumab and hyaluronidase‐oysk during pregnancy can result in oligohydramnios and oligohydramnios sequence manifesting as pulmonary hypoplasia, skeletal abnormalities, and neonatal death. Advise patients of these risks and the need for effective contraception</li>
                </ul>
                <ul className="font-14 font-light popup-list">
                  <li>Exacerbation of chemotherapy‐induced neutropenia has also occurred</li>
                  <li>Hypersensitivity and severe Administration‐Related Reactions (ARRs) including anaphylaxis, have been reported with trastuzumab and hyaluronidase‐oysk. Serious and fatal reactions have been reported after treatment with intravenous trastuzumab products</li>
                </ul>
                <p className="font-14 font-bold mar-b-10">Most Common Adverse Reactions</p>
                <ul className="font-14 font-light popup-list">
                  <li><span className="font-bold">Adjuvant Breast Cancer: </span>Most common adverse reactions for trastuzumab and hyaluronidase‐oysk are fatigue, arthralgia, diarrhea, injection site reaction, upper respiratory tract infection, rash, myalgia, nausea, headache, edema, flushing, pyrexia, cough, and pain in extremity.</li>
                  <li><span className="font-bold">Metastatic Breast Cancer (based on intravenous trastuzumab):</span> The most common adverse reactions are fever, chills, headache, infection, congestive heart failure, insomnia, cough, and rash.</li>
                </ul>
                <p className="font-14 font-light mar-b-10">You may report side effects to the FDA at (800) FDA‐1088 or <a href="https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program" target="_blank" rel="noopener noreferrer">www.fda.gov/medwatch</a>. You may also report side effects to Genentech at (888) 835‐2555.</p>
                <p className="font-14 font-bold mar-b-15"><a href="https://www.gene.com/download/pdf/herceptin_hylecta_prescribing.pdf" target="_blank" rel="noopener noreferrer">Click here</a> for full Prescribing Information for additional Important Safety Information, including BOXED WARNINGS.</p>
              </Modal.Body>
            </Modal>
          </div>




          <div className="treatement-overview-popup">
            <Modal show={this.state.showNeratinib} onHide={this.handleCloseNeratinib}>
              <Modal.Header closeButton>
                  Neratinib-based therapy Indications 
                  </Modal.Header>
              <Modal.Body>
                <p className="font-14 font-bold mar-b-10 "><b>Indications</b></p>
                <p className="font-14 mar-b-10 ">Neratinib is a kinase inhibitor indicated:</p>

                <ul className="font-14 font-light popup-list">
                  <li>As a single agent, for the extended adjuvant treatment of adult patients with early‐stage HER2‐positive breast cancer, to follow adjuvant trastuzumab‐based therapy.</li>
                  <li>In combination with capecitabine, for the treatment of adult patients with advanced or metastatic HER2‐positive breast cancer who have received two or more prior anti‐HER2 based regimens in the metastatic setting.</li>
                </ul>
                <p className="font-14 font-bold mar-b-10 ">Please see full Prescribing Information for Important Safety Information.</p>

              </Modal.Body>
            </Modal>
          </div>

          <div className="treatement-overview-popup">
            <Modal show={this.state.showTucatinibAndTras} onHide={this.handleCloseTucatinibAndTras}>
              <Modal.Header closeButton>
              TUCATINIB + TRASTUZUMAB-BASED THERAPY INDICATION
                  </Modal.Header>
              <Modal.Body>
                <p className="font-14 font-bold mar-b-10 "><b>Indication</b></p>
                <p className="font-14 mar-b-10 ">Tucatinib is indicated in combination with trastuzumab and capecitabine for treatment of adult patients with advanced unresectable or metastatic HER2‐positive breast cancer, including patients with brain metastases, who have received one or more prior anti‐HER2‐based regimens in the metastatic setting.</p>

                <p className="font-14 font-bold mar-b-10 ">Please see full Prescribing Information for Important Safety Information.</p>

              </Modal.Body>
            </Modal>
          </div>

          <div className="treatement-overview-popup">
            <Modal show={this.state.showFamTrastuzumab} onHide={this.handleCloseFamTrastuzumab}>
              <Modal.Header closeButton>
                Fam‐trastuzumab deruxtecan‐nxki (T‐DXd) Indication
                  </Modal.Header>
              <Modal.Body>
                <p className="font-14 font-bold mar-b-10 "><b>Indication</b></p>
                <p className="font-14 font-light mar-b-10 ">Fam‐trastuzumab deruxtecan‐nxki is indicated for the treatment of adult patients with unresectable or metastatic HER2‐positive breast cancer who have received two or more prior anti‐HER2‐based regimens in the metastatic setting.</p>
                <p className="font-14 font-light mar-b-10 ">This indication is approved under accelerated approval based on tumor response rate and duration of response. Continued approval for this indication may be contingent upon verification and description of clinical benefit in a confirmatory trial.</p>
                <p className="font-14 font-bold mar-b-10 ">Please see full Prescribing Information for Important Safety Information.</p>
              </Modal.Body>
            </Modal>
          </div>

          <div className="treatement-overview-popup">
            <Modal show={this.state.showLepatinib} onHide={this.handleCloseLapatinib}>
              <Modal.Header closeButton>
                Lapatinib Indications
                  </Modal.Header>
              <Modal.Body>
                <p className="font-14 font-bold mar-b-10 "><b>Indications</b></p>
                <p className="font-14 font-light mar-b-10 ">Lapatinib is indicated in combination with:</p>
                <ul className="font-14 font-light popup-list">
                  <li>capecitabine for the treatment of patients with advanced or metastatic breast cancer whose tumors overexpress human epidermal growth factor receptor 2 (HER2) and who have received prior therapy including an anthracycline, a taxane, and trastuzumab. <br></br><br></br><u>Limitations of use:</u> Patients should have disease progression on trastuzumab prior to initiation of treatment with lapatinib in combination with capecitabine.</li>
                  <li>letrozole for the treatment of postmenopausal women with hormone‐receptor positive metastatic breast cancer that overexpresses the HER2 receptor for whom hormonal therapy is indicated.</li>
                </ul>
                <p className="font-14 font-light mar-b-10 ">Lapatinib in combination with an aromatase inhibitor has not been compared to a trastuzumab‐containing chemotherapy regimen for the treatment of metastatic breast cancer.</p>
                <p className="font-14 font-bold mar-b-10 ">Please see full Prescribing Information for Important Safety Information.</p>
              </Modal.Body>
            </Modal>
          </div>


        </section>
      </Col>
    )
  }
}

export default QuestionnaireSectionTwo;