import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import TickIcon from '../assets/images/tick.svg';
import HeaderSection from '../containers/HeaderSection';
import FooterSection from '../containers/FooterSection';
import ExpertSection from './ExpertSection';
import QuestionnaireSectionOne from './QuestionnaireSectionOne';
class SurveySectionOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStepOneLink: false
    }
  }

  componentDidMount() {
    if (window.sessionStorage.getItem('questionOneClickStatus') !== null) {
      this.setState({
        showStepOneLink: true
      })
    }
  }

  updateStepOneStatus = (questionStatus) => {
    this.setState({
      showStepOneLink: questionStatus.length !== 0 ? true : false
    })
  }

  render() {
    if (this.props.location.state !== undefined && this.props.location.state === 'final') {

      if (!this.state.showStepOneLink) {
        window.scrollTo(0, 0);
      }
      if ('questionOneClickStatus' in sessionStorage && !this.state.showStepOneLink) {
        window.sessionStorage.removeItem('questionOneClickStatus');
      }
      if ('questionThreeClickStatus' in sessionStorage) {
        window.sessionStorage.removeItem('questionThreeClickStatus');
      }
      if ('questionTwoPayload' in sessionStorage) {
        window.sessionStorage.removeItem('questionTwoPayload');
      }
      if ('questionFourPayload' in sessionStorage) {
        window.sessionStorage.removeItem('questionFourPayload');
      }
      window.history.replaceState(null, '');
    }

    return (
      <Fragment>
        <HeaderSection />
        <section className="survey-section text-center">
          <div className="container">
            <Row>
              <Col>
                <div className="survey-section-inner small">
                  <div className="progress-steps">
                    <ul>
                      <li className={this.state.showStepOneLink ? 'active' : ''}>
                        <div className="circle"><img src={TickIcon} alt="tick" /></div>
                        <h6>STEP 1</h6>
                      </li>
                      <li>
                        <div className="circle"><img src={TickIcon} alt="tick" /></div>
                        <h6>STEP 2</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section>
          <div className="container">
            <Row>
              <ExpertSection />
              <QuestionnaireSectionOne updateStepOneStatus={this.updateStepOneStatus}/>
            </Row>
          </div>
        </section>
        <FooterSection />
      </Fragment>
    )
  }
}

export default SurveySectionOne;