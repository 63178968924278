import React, { Component } from 'react';
import ClientImg1 from '../assets/images/Rao_circle.png';
import ClientImg2 from '../assets/images/Sarah Sammons_circle.png';
import ClientImg3 from '../assets/images/Pallav Mehta_circle.png';
import { Col } from 'react-bootstrap';
import AccordianPlus from '../assets/images/plus.svg';
import AccordianMinus from '../assets/images/minus.svg';
class ExpertSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMoreClassPara1: false,
            showMoreClassPara2: false,
            showMoreClassPara3: false,
            showMoreClassPara4: false,
            showAccordianMobile: false
        }
    }

    toggleContentPara1 = () => {
        this.setState({
            showMoreClassPara1: !this.state.showMoreClassPara1
        })
    }

    toggleContentPara2 = () => {
        this.setState({
            showMoreClassPara2: !this.state.showMoreClassPara2
        })
    }

    toggleContentPara3 = () => {
        this.setState({
            showMoreClassPara3: !this.state.showMoreClassPara3
        })
    }

    toggleContentPara4 = () => {
        this.setState({
            showMoreClassPara4: !this.state.showMoreClassPara4
        })
    }

    toggleAccordian = () => {
        this.setState({
            showAccordianMobile: !this.state.showAccordianMobile
        })
    }

    // collapseAccordian = () => {
    //     this.setState({
    //         showAccordianMobile: false
    //     })
    // }

    showExpertList = () => {
        let width = window.innerWidth;
        if (width < 768 && this.state.showAccordianMobile) {
            return true;
        } else if (width < 768 && !this.state.showAccordianMobile) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        return (
            <Col lg="6" md="12" >
                <section className="Expert-section">
                    <h3 className={!this.state.showAccordianMobile ? 'acc-plus' : 'acc-minus'} onClick={this.toggleAccordian}>Hear from the experts</h3>
                    <div className={this.state.showAccordianMobile ? 'accordian-btns open' : 'accordian-btns'}>
                        {!this.state.showAccordianMobile && <img src={AccordianPlus} alt="Accordian Plus" className="acc-plus" onClick={this.toggleAccordian}/>}
                        {this.state.showAccordianMobile && <img src={AccordianMinus} alt="Accordian Minus" className="acc-minus" onClick={this.toggleAccordian} />}
                    </div>
                    {this.showExpertList() && <ul>
                        <li>
                            <div className="left-expert-section">
                                <img src={ClientImg3} alt="client_image" />
                            </div>
                            <div className="right-expert-section">
                                <h6>Pallav Mehta, MD</h6>
                                <p className="font-16 mar-b-15">Medical Director<br></br> MD Anderson Cancer Center at Cooper<br></br>Camden, NJ</p>
                                <p className={this.state.showMoreClassPara1 ? 'mar-b-10 font-italics expert-content open' : 'mar-b-10 font-italics expert-content'}>Being able to visualize the approved targeted treatment options for my patients is incredibly helpful. With this interactive tool, I am able to see a list of FDA-approved HER2-targeted treatment options. From there, I rely on my own experience to choose an option suitable for my patient.</p>
                                <p className="blue-color show-link" onClick={this.toggleContentPara1}>{!this.state.showMoreClassPara1 ? 'More +' : 'Less -'}</p>
                            </div>
                        </li>

                        <li>
                            <div className="left-expert-section">
                                <img src={ClientImg1} alt="client_image" />
                            </div>
                            <div className="right-expert-section">
                                <h6>Ruta Divgi Rao, MD</h6>
                                <p className="font-16 mar-b-15">Director, Coleman Foundation Comprehensive Breast Cancer Clinic
                                Rush University Medical Center
                                Chicago, IL
                                </p>
                                <p className={this.state.showMoreClassPara2 ? 'mar-b-10 font-italics expert-content open' : 'mar-b-10 font-italics expert-content'}>When weighing approved therapeutic options for my patients with recurrent HER2+ mBC, several factors influence my decision. Is this the first recurrence of HER2+ mBC or has the patient been treated for recurrence before? What are the available approved options? Although not shown in the tool, patient preferences may also factor into the decision.</p>
                                <p className="blue-color show-link" onClick={this.toggleContentPara2}>{!this.state.showMoreClassPara2 ? 'More +' : 'Less -'}</p>
                            </div>
                        </li>
                        <li>
                            <div className="left-expert-section">
                                <img src={ClientImg2} alt="client_image" />
                            </div>
                            <div className="right-expert-section">
                                <h6>Sarah Sammons, MD</h6>
                                <p className="font-16 mar-b-15">Assistant Professor of Medicine<br></br>Duke Health<br></br>Durham, NC
                                </p>
                                <p className={this.state.showMoreClassPara3 ? 'mar-b-10 font-italics expert-content open' : 'mar-b-10 font-italics expert-content'}>Each patient with HER2+ mBC is different. Sometimes I think about their treatment with general categorizations that could apply to all mBC patients. For example, is their disease new or recurrent? What previous mBC treatments did they receive? With these basics covered I consider individual patient characteristics.</p>
                                <p className="blue-color show-link" onClick={this.toggleContentPara3}>{!this.state.showMoreClassPara3 ? 'More +' : 'Less -'}</p>
                            </div>
                        </li>

                    </ul>}
                </section>
            </Col>
        )
    }
}

export default ExpertSection;